@import '../../../styles/variable.scss';

.base-datepicker {
  position: relative;
  width: 100%;

  &__label {
    $font-size: 16px;

    margin-bottom: 0.8rem;
    font-size: $font-size;
    line-height: calc(24 / 16);
    font-weight: 700;
  }

  &__input {
    $font-size: 16px;

    width: 100%;
    padding: 1rem 2rem;
    font-size: $font-size;
    line-height: calc(24 / 16);
    border: 1px solid $light-blue;
    border-radius: 0.4rem;
    outline: 0;
  }

  .react-datepicker {
    &__input-container {
      display: flex;
    }
  }
}
