html {
  font-size: 8px;

  @media screen and (min-width: 768px) {
    font-size: 10px;
  }
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.container {
  display: flex;
  justify-content: center;
  max-width: 1480px;
  min-height: 100vh;
  margin: 0 auto;
}

.section {
  padding: 2rem 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid $light-blue;
  }

  &__title {
    $font-size: 30px;

    margin-bottom: 4.8rem;
    font-size: $font-size;
    line-height: calc(36 / 30);
    font-weight: 700;
  }
}

.title-page {
  $font-size: 48px;

  font-size: $font-size;
  line-height: calc(60 / 48);
  text-align: center;
  font-weight: 700;
}

.link {
  $font-size: 16px;

  margin-bottom: 16px;
  font-size: $font-size;
  line-height: calc(20 / 16);
  color: blue;
  cursor: pointer;
}

.\--disabled {
  opacity: .5;
  pointer-events: none;
}
