@import '../../../styles/variable.scss';

.base-input {
  width: 100%;

  &__label {
    $font-size: 16px;

    margin-bottom: 0.8rem;
    font-size: $font-size;
    line-height: calc(24 / 16);
    font-weight: 700;
  }

  &__control {
    position: relative;
    display: flex;

    &-input {
      $font-size: 16px;

      width: 100%;
      padding: 1rem 2rem;
      font-size: $font-size;
      line-height: calc(24 / 16);
      border: 1px solid $light-blue;
      border-radius: 0.4rem;
      outline: 0;

      &.\--has-icon {
        padding-right: 5rem;
      }
    }

    &-icon {
      position: absolute;
      top: 50%;
      right: 2rem;
      transform: translateY(-50%);
      font-size: 22px;

      &.\--pointer {
        cursor: pointer;
      }
    }
  }

  &__error-message {
    $font-size: 12px;

    font-size: $font-size;
    line-height: calc(15 / 12);
    color: red;
  }
}
