.base-radio-group {
  width: 100%;

  &__label {
    $font-size: 16px;

    margin-bottom: 0.8rem;
    font-size: $font-size;
    line-height: calc(24 / 16);
    font-weight: 700;
  }

  &__group-item {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;
    width: max-content;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &-input {
      margin: 0;
      cursor: pointer;
    }

    &-text {
      $font-size: 16px;

      margin-left: 0.5rem;
      font-size: $font-size;
      line-height: calc(24 / 16);
      font-weight: 700;
    }
  }
}
