@import '../../../styles/variable.scss';

.base-textarea {
  width: 100%;

  &__label {
    $font-size: 16px;

    margin-bottom: 0.8rem;
    font-size: $font-size;
    line-height: calc(24 / 16);
    font-weight: 700;
  }

  &__control {
    position: relative;
    display: flex;

    &-input {
      $font-size: 16px;

      width: 100%;
      height: 15rem;
      padding: 1rem 2rem;
      font-size: $font-size;
      line-height: calc(24 / 16);
      border: 1px solid $light-blue;
      border-radius: 0.4rem;
      outline: 0;
      resize: none;
    }
  }

  &__error-message {
    $font-size: 12px;

    font-size: $font-size;
    line-height: calc(15 / 12);
    color: red;
  }
}
