.header-component {
  display: flex;
  align-items: flex-end;
  padding: 2rem;
  max-width: 100%;
  height: 50rem;
  font-size: 20px;
  line-height: calc(28 / 20);
  color: white;
  background: url('../../../public/images/homepage.jpg') center center no-repeat;
  background-size: cover;

  &.afternoon {
    background-image: url('../../../public//images/homepage.jpg');
  }

  &.evening {
    background-image: url('../../../public//images/homepage.jpg');
  }

  &.night {
    background-image: url('../../../public//images/homepage.jpg');
  }

  &__menu {
    flex: 1;
    display: flex;
    margin-left: 10rem;

    &-item {
      margin-right: 1.6rem;
      color: white;
      text-decoration: none;
      cursor: pointer;

      &.active {
        color: lightcyan;
        pointer-events: none;
      }

      &:hover {
        color: grey;
      }

      &:last-child {
        margin-right: 0;
      }

      &.\--auto {
        flex: 1;
        cursor: default;
      }
    }
  }
}
