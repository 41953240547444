.report-component {
  width: 100%;
  height: 100%;
  margin-bottom: 1.6rem;

  &__body {
    display: flex;
    flex-direction: column;
    margin-top: 1.6rem;

    &-action {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .base-select {
        max-width: 20rem;
      }

      .btn {
        min-width: auto;
        width: auto;
        text-decoration: none;
      }
    }
  }

  &__chart-group {
    &__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 4rem;
      margin-top: 1.6rem;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        align-items: flex-start;
      }
    }

    width: 45rem;
    margin-bottom: 1rem;

    &__title {
      $font-size: 20px;

      margin-bottom: 1rem;
      font-size: $font-size;
      line-height: calc(28 / 20);
      font-weight: 700;
    }

    @media screen and (min-width: 768px) {
      width: 50rem;
    }
  }
}
