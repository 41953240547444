.btn {
  $font-size: 14px;

  min-width: 18rem;
  padding: 1rem 1.5rem;
  font-size: $font-size;
  line-height: calc(16 / 14);
  border: 1px solid blue;
  background-color: white;
  border-radius: 6px;
  color: black;
  cursor: pointer;

  &:hover {
    border-color: white;
    background-color: blue;
    color: white;
  }

  &.\--blue {
    border-color: white;
    background-color: blue;
    color: white;

    &:hover {
      border-color: blue;
      background-color: white;
      color: blue;
    }
  }

  &.\--disabled {
    pointer-events: none;
    opacity: .5;
  }
}
