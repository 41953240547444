// white
$white: #fff;
$white-1: #f3f3f3;
$white-2: #dddddd;

// blue
$light-blue: #add8e6;
$blue-1: #3498db;

// black
$black: #000;
