.component-table {
  $font-size: 16px;

  width: 100%;
  font-size: $font-size;
  line-height: calc(24 / 16);
  text-align: left;
  border-collapse: collapse;

  &__wrapper {
    overflow: auto;
  }

  &__td {
    padding: 8px;
    border: 1px solid #ddd;
  }

  &__head {
    color: grey;

    .component-table__td {
      font-weight: 700;
    }
  }

  &__tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  &__no-result {
    $font-size: 16px;

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 1.6rem 0;
    }

    font-size: $font-size;
    line-height: calc(24 / 16);
    font-weight: 700;
  }
}
