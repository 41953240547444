.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 51000;

  &__overlay {
    background-color: rgba(0, 0, 0, .25);
    width: 100%;
    height: 100%;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-height: 90%;
    overflow: auto;
    border-radius: 4px;
    background-color: #ffffff;

    @media screen and (min-width: 480px) {
      max-width: 52rem;
    }
  }
}
