.login-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 45rem;

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .btn {
      align-self: flex-end;
    }
  }

  .base-input {
    margin-bottom: 24px;
  }
}
