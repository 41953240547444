.toast-component {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 51001;

  &__inner {
    display: flex;
    padding: 0.8rem 1.6rem;
    background-color: lightblue;
    border-radius: 0.6rem;
  }

  &__icon {
    margin-right: 0.8rem;
  }

  &__content {
    $font-size: 13px;

    font-size: $font-size;
    line-height: calc(16 / 13);
  }

  .fa-close {
    margin-left: 0.8rem;
    cursor: pointer;
  }
}
