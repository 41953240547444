.homepage-component {
  width: 100%;
  height: 100%;

  &__filter-group {
    display: flex;
    align-items: center;
    gap: 16px;

    .base-select {
      max-width: 320px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 24px;

    .btn {
      align-self: flex-end;
      min-width: 8rem;
    }
  }

  &__group-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    &-item {
      cursor: pointer;

      &.\--disabled {
        pointer-events: none;
        color: grey;
      }
    }
  }

  .remove-popup {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 2rem;

    &__title {
      $font-size: 30px;

      font-size: $font-size;
      line-height: calc(36 / 30);
      font-weight: 700;
    }

    &__description {
      $font-size: 16px;

      font-size: $font-size;
      line-height: calc(24 / 16);
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1.6rem;

      .btn {
        min-width: 8rem;
      }
    }
  }
}
