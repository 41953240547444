.change-password-component {
  width: 100%;
  height: 100%;
  margin-bottom: 1.6rem;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    max-width: 45rem;
    width: 100%;
    margin: 1.6rem auto;

    .btn {
      align-self: flex-end;
    }

    @media screen and (min-width: 768px) {
      max-width: 50rem;
    }
  }
}
