.component-loader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(white, .7);
  z-index: 10;

  &__inner {
    width: 20px;
    height: 20px;
    border: 2px solid grey;
    border-top: 2px solid blue;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }

  &.\--position-static {
    position: static;
    background-color: transparent;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
