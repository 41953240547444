.component-create-popup {
  &__form {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 2rem;

    &-info {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      text-align: left;

      &-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1.6rem;

      .btn {
        min-width: 8rem;
      }
    }

    .divider {
      width: 100%;
      margin: 0;
      border: 1px dashed lightgray;
    }

    .title {
      font-size: 30px;
      line-height: 1.2;
      font-weight: 700;
    }
  }
}
